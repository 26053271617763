
























































































import TallaAdd from "@/components/admin/evento/TallaAdd.vue";
import Vue from "vue";
import ModificarDireccionPedido from "@/components/web/cliente/ModificarDireccionPedido.vue";
//import TallaService, { TallaCreateDto } from "@/api/TallaService";
import CarreraService from "@/api/CarreraService";
export default Vue.extend({
  components: {
    ModificarDireccionPedido
  },
  metaInfo: {
    title: "Tallas"
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: "",
        descripcion: ""
      } as any,
      headers: [
        { text: "#", value: "id" },
        { text: "Fecha Registro", value: "createdOn", sortable: false },
        { text: "Carrera", value: "carrera.nombre" },
        //{ text: "# Camiseta", value: "numeroCamiseta" },
        { text: "Talla Camiseta", value: "tallaCamiseta.talla.nombre" },
        { text: "Personalización", value: "personalizacionCamiseta" },
        //{ text: "Tipo Pago", value: "pedido.displayTipoPago" },
        //{ text: "Estado Pago", value: "pedido.displayEstadoPago" },
        //{ text: "Motivo Rechazo", value: "pedido.motivoRechazo" },
        //{ text: "Estado Envio", value: "displayEstadoEnvio" },
        //{ text: "Estado Entrega", value: "displayEstadoEntrega" },
        { text: "Total", value: "pedido.displayTotal" },
        //{ text: "Estado", value: "estado" },
        { text: "Pagar", value: "actions2", sortable: false, align: "center" },
        {
          text: "Kit Deportivo",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ],
      datos: [] as any[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        descripcion: ""
      };
      this.dato = newDto;
    },
    toSubirRecibo(id: string) {
      this.$router.push({
        path: "/proceso-pago/subir-papeleta",
        query: { idPedido: id }
      });
    },
    editItem(item: any) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      CarreraService.getMisRegistros()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Registros",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
